import Image from 'next/image';

interface IProps {
    desc?: string;
    userName?: string;
    name?: string;
}

const FeedbackSection: React.FC<IProps> = ({
    desc = '',
    userName = '',
    name = '',
}) => {
    return (
        <>
            <div
                className="xsMax:hidden block  md:flex absolute top-0 right-0 left-0 bottom-0 bg-no-repeat bg-cover bg-center"
                style={{
                    backgroundImage: `url('/images/home/header${name}-mobile.jpg')`,
                }}
            />
            <div
                className="xsMax:block hidden md:flex absolute top-0 right-0 left-0 bottom-0 bg-no-repeat bg-cover bg-center"
                style={{
                    backgroundImage: `url('/images/home/header${name}.jpg')`,
                }}
            />
            {/* <div className="absolute top-0 bottom-0 right-0 left-0 bg-grey-ebony opacity-70" /> */}
            <div className=" absolute top-0 bottom-0 right-0 left-0 flex flex-col space-y-4 items-center justify-center z-10 px-6 ">
                <Image
                    width={50}
                    height={35}
                    className="w-8 opacity-50"
                    src="/images/home/section-3-icon.svg"
                    alt=""
                />
                <p className="text-white text-3xl md:text-4xl font-medium leading-34px md:leading-51px max-w-5xl text-center font-Circular-Std md:mx-0 -mx-1 md:pt-0 pt-2">
                    {desc ||
                        'I use Lineviewpals to give my videos the occasional boost. It’s fast and affordable. That’s all I need.'}
                </p>
                <p className={`md:text-lg text-15px font-semibold font-Inter text-primary`}>
                    {userName || '- Jenny Walts'}
                </p>
            </div>
        </>
    );
};

export default FeedbackSection;
