import React, { ComponentType, ReactElement } from 'react';
import APP_CONFIG from './APP_CONFIG';

const FAQ_DATA = {
    homepage: [
        {
            key: '1',
            title: 'What types of content do you offer copywriting services for?',
            content: (): ReactElement => (
                <span>
                    We offer copywriting services for a variety of content
                    types, including but not limited to: blog posts, social
                    media posts, product descriptions, website content, email
                    marketing campaigns, and more.
                </span>
            ),
        },
        {
            key: '2',
            title: 'How long does it take to receive my finished content?',
            content: (): ReactElement => (
                <span>
                    Our turnaround time varies depending on the type and length
                    of content requested. However, we strive to return all
                    content within 4-5 working days. Shorter blog posts or
                    social media posts of 300 words or less will likely be
                    returned sooner.
                </span>
            ),
        },
        {
            key: '3',
            title: 'How many revisions are included in your service?',
            content: (): ReactElement => (
                <span>
                    We include up to 2 rounds of revisions to ensure you are
                    completely satisfied with the finished content.
                </span>
            ),
        },
        {
            key: '4',
            title: 'How do I place an order for your copywriting services?',
            content: (): ReactElement => (
                <span>
                    Simply visit our website and select the type of content you
                    need, and specify the desired word count or character count.
                    After checkout, we’ll provide instructions on where to send
                    your brief for your content.
                </span>
            ),
        },
        {
            key: '5',
            title: 'Do you have a team of experienced copywriters?',
            content: (): ReactElement => (
                <span>
                    Yes, our team of copywriters has over 8 years of experience
                    across various fields, ensuring that we can provide
                    high-quality content tailored to your specific needs.
                </span>
            ),
        },
        {
            key: '6',
            title: 'Do you offer discounts for bulk orders?',
            content: (): ReactElement => (
                <span>
                    Yes, we offer discounts for bulk orders. The more posts you
                    select, the lower the cost per post.
                </span>
            ),
        },
        {
            key: '7',
            title: 'What is the process for providing feedback and requesting revisions?',
            content: (): ReactElement => (
                <span>
                    Once we have returned your content, you can provide feedback
                    and request revisions by replying to the email with your
                    feedback. Our team will then work with you to make any
                    desired changes and ensure your complete satisfaction.
                </span>
            ),
        },
    ],
};

export type ItemProps = {
    key: string;
    title: string;
    content: ComponentType<{}>;
};

export default FAQ_DATA;
